import React, { useEffect } from "react";
import { useSelector } from "react-redux";

function AvailablePlants() {
  const usersList = useSelector((state: any) => state.usersList);
  const plantsList = useSelector((state: any) => state.plantsList);
  const userAccessResponse = useSelector((state: any) => state.userAccess);
  const userpermissionsList = useSelector((state: any) => state.userPermissionsList);
  const [availablePlants, setAvailablePlants]: any = React.useState([]);
  let userAccess = userAccessResponse?.userAccess;
  let UserprofileAccess = userAccess
    ? userAccess[0]?.profile_based_access
    : null;
  let isAdminRole = UserprofileAccess ? UserprofileAccess?.isAdminRole : null;
  useEffect(() => {
    const downtimeAdminId = userpermissionsList.userPermissions.find(
      (ele: any) => {
        return ele.role_name === "ARCH_SCM_ADMIN";
      }
    );

    const isDowntimeAdmin = userAccess
      ? userAccess[0]?.user_arch_group?.includes(downtimeAdminId?._id)
      : false;

    if (isDowntimeAdmin) {
      setAvailablePlants(usersList.accessiblePlants);
    } else if (
      plantsList.plants.length > 0 &&
      usersList.scmUserPlantGroup &&
      usersList.scmUserPlantGroup.length > 0 &&
      isAdminRole !== null &&
      !isAdminRole
    ) {
      setAvailablePlants(
        plantsList.plants.filter((plant: any) =>
          usersList.scmUserPlantGroup.includes(plant._id)
        )
      );
    } else {
      setAvailablePlants(usersList.accessiblePlants);
    }
  }, []);

  return availablePlants;
}

export default AvailablePlants;
