import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import { PartialRouteObject } from "react-router";
import SidebarWithHeaderLayout from "../layouts/SideBarWithHeaderLayout";
import SuspenseLoader from "../componets/SuspenseLoader";
import UtilitiesDashbord from "../pages/master/UsecaseDashboard/Utilities";
import SupplyChain from "../pages/master/UsecaseDashboard/SupplyChainTicket";
import TicketDetailsSupplyChain from "../pages/master/UsecaseDashboard/SupplyChainTicket/TicketDetailsSupplyChain";
import DowntimeAlerts from "../pages/master/UsecaseDashboard/SupplyChainTicket/Components/DowntimeAlerts";
import ViewRequest from "../pages/master/UsecaseDashboard/EAMScheduler/ViewRequest";
import DisconnectedSensors from "../pages/master/UsecaseDashboard/MotorsAndPumpsDashboard/DisconnectedSensors";

const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages
const Home = Loader(lazy(() => import("../pages/home")));

//Master pages
const LocationMap = Loader(lazy(() => import("../pages/master/location-map")));
const LocationList = Loader(
  lazy(() => import("../pages/master/location-list"))
);
const Usecase = Loader(lazy(() => import("../pages/master/usecase")));
const Pool = Loader(lazy(() => import("../pages/master/pool")));
const Types = Loader(lazy(() => import("../pages/master/types")));
const Mapping = Loader(lazy(() => import("../pages/master/mapping")));
const AssetHierarchy = Loader(
  lazy(() => import("../pages/master/assetHierarchy"))
);

// const PBIReports = Loader(lazy(() => import("../pages/master/PBIReports")));

// const PBIWithAsset = Loader(lazy(() => import("../pages/master/PBIWithAsset")));
// const PBIWithPlant = Loader(lazy(() => import("../pages/master/PBIWithPlant")));
// const PBIWithUsecase = Loader(
//   lazy(() => import("../pages/master/PBIWithUsecase"))
// );

// const ReportMapping = Loader(
//   lazy(() => import("../pages/master/ReportMapping"))
// );

const Unauthorized = Loader(lazy(() => import("../pages/master/Unauthorized")));
// const UserShell = Loader(lazy(() => import("../pages/master/User/index")));

//Maintainence pages
const Tickets = Loader(lazy(() => import("../pages/maintainence/tickets")));
const Notification = Loader(lazy(() => import("../pages/master/notification")));
const TicketDetails = Loader(
  lazy(() => import("../pages/maintainence/ticket-details"))
);
const NotifyDetails = Loader(
  lazy(() => import("../pages/maintainence/notify-details"))
);
const Profile = Loader(lazy(() => import("../pages/master/User")));
const Logs = Loader(lazy(() => import("../pages/maintainence/LogsPage")));
const MotorsAndPumpsDashboard = Loader(
  lazy(() => import("../pages/master/UsecaseDashboard/MotorsAndPumpsDashboard"))
);
const WQMSDashboard = Loader(
  lazy(() => import("../pages/master/UsecaseDashboard/WQMSDashboard"))
);
const ServoDrives = Loader(
  lazy(() => import("../pages/master/UsecaseDashboard/ServoDrives"))
);
const AirLeaksDashboard = Loader(
  lazy(() => import("../pages/master/UsecaseDashboard/AirLeaksMonitoring"))
);

// const RunHoursDashboard = Loader(
//   lazy(() => import("../pages/master/UsecaseDashboard/RunHours"))
// );

const RunHoursDashboardV2 = Loader(
  lazy(() => import("../pages/master/UsecaseDashboard/RunHours V2"))
);

const AsepticCCPDashboard = Loader(
  lazy(() => import("../pages/master/UsecaseDashboard/AsepticCCP"))
);

// const InjectionAnalytics = Loader(
// 	lazy(() => import('../pages/master/UsecaseDashboard/InjectionAnalytics'))
// )
// const InjectionTicketsList = Loader(
// 	lazy(
// 		() => import('../pages/master/UsecaseDashboard/InjectionAnalytics/AllTicketsPage')
// 	)
// )
// const InjectionTicketsDetails = Loader(
// 	lazy(
// 		() => import('../pages/master/UsecaseDashboard/InjectionAnalytics/TicketDetailsPage')
// 	)
// )
// const InjectionAnalyticsConfig = Loader(
// 	lazy(() => import('../pages/master/UsecaseDashboard/InjectionAnalytics/ConfigPage'))
// )

const AsepticCIP = Loader(
  lazy(() => import("../pages/master/UsecaseDashboard/AsepticCIP"))
);

const WashDetails = Loader(
  lazy(() => import("../pages/master/UsecaseDashboard/AsepticCIP/WashDetails"))
);

const WashAlertRedirectionPage = Loader(
  lazy(
    () =>
      import("../pages/master/UsecaseDashboard/AsepticCIP/AlertRedirectionPage")
  )
);

const WashTable = Loader(
  lazy(() => import("../pages/master/UsecaseDashboard/AsepticCIP/WashTable"))
);

const AsepticCCPTroubleshootDashboard = Loader(
  lazy(
    () =>
      import("../pages/master/UsecaseDashboard/AsepticCCP/TroubleShootParent")
  )
);
const AsepticTransmittersDashboard = Loader(
  lazy(() => import("../pages/master/UsecaseDashboard/AsepticTransmitters"))
);
const AsepticValvesDashboard = Loader(
  lazy(() => import("../pages/master/UsecaseDashboard/AsepticValves"))
);
const AssetReportPage = Loader(
  lazy(() => import("../Utils/AssetsReportPage/AssetReportPage"))
);
const EAMSchedulerDashboard = Loader(
  lazy(() => import("../pages/master/UsecaseDashboard/EAMScheduler"))
);
const SatDashboardPage = Loader(
  lazy(() => import("../pages/master/UsecaseDashboard/SatDashboard"))
);
const SatSignOffDashboardPage = Loader(
  lazy(() => import("../pages/master/UsecaseDashboard/SATSignOff"))
);
const SatSignOffHistoryDashboardPage = Loader(
  lazy(() => import("../pages/master/UsecaseDashboard/SATSignOffHistory"))
);
const GrantUserAccessPage = Loader(
  lazy(() => import("../pages/master/User/GrantAccess"))
);
const ExceptionsList = Loader(
  lazy(
    () => import("../pages/master/UsecaseDashboard/AsepticCIP/ExceptionsList")
  )
);
const InspectionSystemDashboard = Loader(
  lazy(() => import("../pages/master/UsecaseDashboard/AGRInspectionSystem"))
);
const adminRoutes: PartialRouteObject[] = [
  {
    path: "/",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="usecase/motors-and-pumps" replace />,
      },
      {
        path: "asset-management/usecase",
        element: <Usecase />,
      },
      {
        path: "asset-management/pool",
        element: <Pool />,
      },
      {
        path: "asset-management/types",
        // element: <Types />,
        element: <Types />,
      },
      {
        path: "asset-management/mapping",
        // element: <Mapping />,
        element: <Mapping />,
      },
      {
        path: "plants",
        children: [
          {
            path: "*",
            element: <AssetHierarchy />,
          },
        ],
      },
    ],
  },
  {
    path: "eam-prod-overlay",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="eam-prod-overlay/scheduler" replace />,
      },
      {
        path: "scheduler",
        element: <EAMSchedulerDashboard />,
      },
    ],
  },
  {
    path: "sat",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="/sat/sat-current/:plantId" />,
      },
      {
        path: "/sat/sat-current/:plantId",
        element: <SatDashboardPage />,
      },
      {
        path: "sat-sign-off/:plantId",
        element: <SatSignOffDashboardPage />,
      },
      {
        path: "sat-sign-off-history",
        element: <SatSignOffHistoryDashboardPage />,
      },
      // {
      //   path: "sat-historical",
      //   element: <SatDashboardPage />,
      // },
    ],
  },
  {
    path: "locations",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="/locations/list" replace />,
      },
      {
        path: "list",
        element: <LocationList />,
      },
    ],
  },
  {
    path: "usecase",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "motors-and-pumps",
        element: <MotorsAndPumpsDashboard />,
      },
      {
			  path: "motors-and-pumps/disconnectedsensors/:plantId",
			  element: <DisconnectedSensors />,
			},
      {
        path: "utilities",
        element: <UtilitiesDashbord />,
      },
      // {
      //   path: "run-hours",
      //   element: <RunHoursDashboard />,
      // },
      {
        path: "run-hours-pm",
        element: <RunHoursDashboardV2 />,
      },
      {
        path: "wqms",
        element: <WQMSDashboard />,
      },
      {
        path: "servo-drives",
        element: <ServoDrives />,
      },
      {
        path: "aseptic-ccp",
        element: <AsepticCCPDashboard />,
      },
      {
        path: "aseptic-transmitters",
        element: <AsepticTransmittersDashboard />,
      },
      {
        path: "aseptic-valves",
        element: <AsepticValvesDashboard />,
      },
      {
        path: "air-leaks-monitoring",
        element: <AirLeaksDashboard />,
      },
      {
        path: "aseptic-ccp/troubleshoot",
        element: <AsepticCCPTroubleshootDashboard />,
      },
      {
        path: "downtime-request",
        element: <SupplyChain />,
      },
      {
        path: "downtime-request/alerts",
        element: <DowntimeAlerts />,
      },
      {
        path: "downtime-request/list-details/:ticketId",
        element: <TicketDetailsSupplyChain />,
      },
      {
        path: "aseptic-cip",
        element: <AsepticCIP />,
      },
      {
        path: "aseptic-cip/washtable/:plant",
        element: <WashTable />,
      },
      {
        path: "aseptic-cip/exceptions/:plant",
        element: <ExceptionsList />,
      },
      {
        path: "inspection-system",
        element: <InspectionSystemDashboard />,
      },
      // {
			// 	path: 'injection-analytics',
			// 	element: <InjectionAnalytics />
			// },
			// {
			// 	path: 'injection-analytics/all-tickets',
			// 	element: <InjectionTicketsList />
			// },
			// {
			// 	path: 'injection-analytics/ticket-details/:ticketId',
			// 	element: <InjectionTicketsDetails />
			// },
			// {
			// 	path: 'injection-analytics/config/:reportType',
			// 	element: <InjectionAnalyticsConfig />
			// },
      // {
      //   path: "injection-analytics/cycle-count-monitoring",
      //   element: <InjectionAnalytics />,
      // },
      // {
      //   path: "injection-analytics/cavity-monitoring",
      //   element: <InjectionAnalytics />,
      // },
      // {
      //   path: "injection-analytics/cycle-time-monitoring",
      //   element: <InjectionAnalytics />,
      // }
    ],
  },
  {
    path: "aseptic-cip",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "wash-alert",
        children: [
          {
            path: ":alertId",
            element: <WashAlertRedirectionPage />,
          },
        ],
      },
      {
        path: "wash-details",
        children: [
          {
            path: ":washId",
            element: <WashDetails />,
          },
        ],
      },
    ],
  },
  {
    path: "usecase",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "motors-and-pumps/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "utilities/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "wqms/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "servo-drives/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "air-leaks-monitoring/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "aseptic-transmitters/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "aseptic-valves/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "aseptic-ccp/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "inspection-system/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "aseptic-cip",
        element: <AsepticCIP />,
      },
      {
        path: "aseptic-cip/washtable/:plant",
        element: <WashTable />,
      },
      {
        path: "aseptic-cip/exceptions/:plant",
        element: <ExceptionsList />,
      },
      // {
			// 	path: 'injection-analytics',
			// 	element: <InjectionAnalytics />
			// },
			// {
			// 	path: 'injection-analytics/all-tickets',
			// 	element: <InjectionTicketsList />
			// },
			// {
			// 	path: 'injection-analytics/ticket-details/:ticketId',
			// 	element: <InjectionTicketsDetails />
			// },
			// {
			// 	path: 'injection-analytics/config/:reportType',
			// 	element: <InjectionAnalyticsConfig />
			// },
      // {
      //   path: "injection-analytics/cycle-count-monitoring",
      //   element: <InjectionAnalytics />,
      // },
      // {
      //   path: "injection-analytics/cavity-monitoring",
      //   element: <InjectionAnalytics />,
      // },
      // {
      //   path: "injection-analytics/cycle-time-monitoring",
      //   element: <InjectionAnalytics />,
      // }
    ],
  },
  {
    path: "aseptic-cip",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "wash-alert",
        children: [
          {
            path: ":alertId",
            element: <WashAlertRedirectionPage />,
          },
        ],
      },
      {
        path: "wash-details",
        children: [
          {
            path: ":washId",
            element: <WashDetails />,
          },
        ],
      },
    ],
  },

  {
    path: "maintenance",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="/maintenance/tickets" replace />,
      },
      {
        path: "tickets",
        element: <Tickets />,
      },
      {
        path: "ticket-details",
        children: [
          {
            path: ":ticketId",
            element: <TicketDetails />,
          },
          {
            path: ":ticketId/:messageId",
            element: <TicketDetails />,
          },
        ],
      },
      {
        path: "notifications",
        element: <Notification />,
      },
      {
        path: "notification-details",
        children: [
          {
            path: "*",
            element: <NotifyDetails />,
          },
        ],
      },
    ],
  },
  {
    path: "management",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "usecase-logs",
        element: <Logs />,
      },
      {
        path: "admin-logs",
        element: <Logs />,
      },
      {
        path: "asset-hierarchy-logs",
        element: <Logs />,
      },
      {
        path: "monarch-logs",
        element: <Logs />,
      },
      {
        path: "profile",
        children: [
          {
            path: "",
            element: <Profile />,
          },
        ],
      },
      {
        path: "preferences",
        children: [
          {
            path: "",
            element: <Profile />,
          },
        ],
      },
      {
        path: "user-management",
        children: [
          {
            path: "",
            element: <Profile />,
          },
        ],
      },
      {
        path: "send-email",
        children: [
          {
            path: "",
            element: <Profile />,
          },
        ],
      },
      {
        path: "request-access",
        children: [
          {
            path: "",
            element: <Profile />,
          },
        ],
      },
      {
        path: "grant-access",
        children: [
          {
            path: "",
            element: <GrantUserAccessPage />,
          },
        ],
      },
    ],
  },
];

const nonAdminRoutes: PartialRouteObject[] = [
  {
    path: "/",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="/usecase/motors-and-pumps" replace />,
      },
      {
        path: "asset-management/usecase",
        element: <Unauthorized />,
      },
      {
        path: "asset-management/pool",
        element: <Unauthorized />,
      },
      {
        path: "asset-management/types",
        // element: <Types />,
        element: <Unauthorized />,
      },
      {
        path: "asset-management/mapping",
        // element: <Mapping />,
        element: <Unauthorized />,
      },

      {
        path: "plants",
        children: [
          {
            path: "*",
            element: <AssetHierarchy />,
          },
        ],
      },
      {
        path: "*",
        element: <Unauthorized />,
      },
    ],
  },
  {
    path: "eam-prod-overlay",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="eam-prod-overlay/scheduler" replace />,
      },
      {
        path: "scheduler",
        element: <EAMSchedulerDashboard />,
      },
    ],
  },
  {
    path: "sat",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="/sat/sat-current/:plantId" />,
      },
      {
        path: "/sat/sat-current/:plantId",
        element: <SatDashboardPage />,
      },
      {
        path: "sat-sign-off/:plantId",
        element: <SatSignOffDashboardPage />,
      },
      // {
      //   path: "sat-historical",
      //   element: <SatDashboardPage />,
      // },
    ],
  },
  {
    path: "locations",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="/locations/list" replace />,
      },
      {
        path: "list",
        element: <LocationList />,
      },
    ],
  },
  {
    path: "usecase",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "motors-and-pumps",
        element: <MotorsAndPumpsDashboard />,
      },
      {
			  path: "motors-and-pumps/disconnectedsensors/:plantId",
			  element: <DisconnectedSensors />,
			},
      {
        path: "utilities",
        element: <UtilitiesDashbord />,
      },
      // {
      //   path: "run-hours",
      //   element: <RunHoursDashboard />,
      // },
      {
        path: "run-hours-pm",
        element: <RunHoursDashboardV2 />,
      },
      {
        path: "wqms",
        element: <WQMSDashboard />,
      },
      {
        path: "servo-drives",
        element: <ServoDrives />,
      },
      { path: "aseptic-ccp", element: <AsepticCCPDashboard /> },
      {
        path: "aseptic-transmitters",
        element: <AsepticTransmittersDashboard />,
      },
      {
        path: "aseptic-valves",
        element: <AsepticValvesDashboard />,
      },
      {
        path: "air-leaks-monitoring",
        element: <AirLeaksDashboard />,
      },
      {
        path: "aseptic-ccp/troubleshoot",
        element: <AsepticCCPTroubleshootDashboard />,
      },
      {
        path: "downtime-request",
        element: <SupplyChain />,
      },
      {
        path: "downtime-request/alerts",
        element: <DowntimeAlerts />,
      },
      {
        path: "downtime-request/list-details/:ticketId",
        element: <TicketDetailsSupplyChain />,
      },
      {
        path: "aseptic-cip",
        element: <AsepticCIP />,
      },
      {
        path: "aseptic-cip/washtable/:plant",
        element: <WashTable />,
      },
      {
        path: "aseptic-cip/exceptions/:plant",
        element: <ExceptionsList />,
      },
      {
        path: "inspection-system",
        element: <InspectionSystemDashboard />,
      },
      // {
			// 	path: 'injection-analytics',
			// 	element: <InjectionAnalytics />
			// },
			// {
			// 	path: 'injection-analytics/all-tickets',
			// 	element: <InjectionTicketsList />
			// },
			// {
			// 	path: 'injection-analytics/ticket-details/:ticketId',
			// 	element: <InjectionTicketsDetails />
			// },
			// {
			// 	path: 'injection-analytics/config/:reportType',
			// 	element: <InjectionAnalyticsConfig />
			// },
      // {
      //   path: "injection-analytics/cycle-count-monitoring",
      //   element: <InjectionAnalytics />,
      // },
      // {
      //   path: "injection-analytics/cavity-monitoring",
      //   element: <InjectionAnalytics />,
      // },
      // {
      //   path: "injection-analytics/cycle-time-monitoring",
      //   element: <InjectionAnalytics />,
      // }
    ],
  },
  {
    path: "aseptic-cip",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "wash-alert",
        children: [
          {
            path: ":alertId",
            element: <WashAlertRedirectionPage />,
          },
        ],
      },
      {
        path: "wash-details",
        children: [
          {
            path: ":washId",
            element: <WashDetails />,
          },
        ],
      },
    ],
  },

  {
    path: "usecase",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "motors-and-pumps/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "utilities/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "wqms/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "servo-drives/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "air-leaks-monitoring/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "aseptic-transmitters/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "aseptic-valves/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "aseptic-ccp/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "inspection-system/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "aseptic-cip",
        element: <AsepticCIP />,
      },
      {
        path: "aseptic-cip/washtable/:plant",
        element: <WashTable />,
      },
      {
        path: "aseptic-cip/exceptions/:plant",
        element: <ExceptionsList />,
      },
      // {
			// 	path: 'injection-analytics',
			// 	element: <InjectionAnalytics />
			// },
			// {
			// 	path: 'injection-analytics/all-tickets',
			// 	element: <InjectionTicketsList />
			// },
			// {
			// 	path: 'injection-analytics/ticket-details/:ticketId',
			// 	element: <InjectionTicketsDetails />
			// },
      // {
			// 	path: 'injection-analytics/config/:reportType',
			// 	element: <InjectionAnalyticsConfig />
			// },
      // {
      //   path: "injection-analytics/cycle-count-monitoring",
      //   element: <InjectionAnalytics />,
      // },
      // {
      //   path: "injection-analytics/cavity-monitoring",
      //   element: <InjectionAnalytics />,
      // },
      // {
      //   path: "injection-analytics/cycle-time-monitoring",
      //   element: <InjectionAnalytics />,
      // }
    ],
  },
  {
    path: "aseptic-cip",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "wash-alert",
        children: [
          {
            path: ":alertId",
            element: <WashAlertRedirectionPage />,
          },
        ],
      },
      {
        path: "wash-details",
        children: [
          {
            path: ":washId",
            element: <WashDetails />,
          },
        ],
      },
    ],
  },

  {
    path: "maintenance",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="/tickets/lists" replace />,
      },
      {
        path: "tickets",
        element: <Tickets />,
      },
      {
        path: "ticket-details",
        children: [
          {
            path: ":ticketId",
            element: <TicketDetails />,
          },
          {
            path: ":ticketId/:messageId",
            element: <TicketDetails />,
          },
        ],
      },
      {
        path: "notifications",
        element: <Notification />,
      },
      {
        path: "notification-details",
        children: [
          {
            path: "*",
            element: <NotifyDetails />,
          },
        ],
      },
    ],
  },
  {
    path: "management",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "profile",
        children: [
          {
            path: "",
            element: <Profile />,
          },
        ],
      },
      {
        path: "preferences",
        children: [
          {
            path: "",
            element: <Profile />,
          },
        ],
      },
      {
        path: "user-management",
        children: [
          {
            path: "",
            element: <Unauthorized />,
          },
        ],
      },
      {
        path: "request-access",
        children: [
          {
            path: "",
            element: <Profile />,
          },
        ],
      },
      {
        path: "grant-access",
        children: [
          {
            path: "",
            element: <GrantUserAccessPage />,
          },
        ],
      },
    ],
  },
];
// DEVELOPER ROUTES STARTS HERE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
const developerRoutes: PartialRouteObject[] = [
  {
    path: "/",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="usecase/motors-and-pumps" replace />,
      },
      {
        path: "asset-management/usecase",
        element: <Usecase />,
      },
      {
        path: "asset-management/pool",
        element: <Pool />,
      },
      {
        path: "asset-management/types",
        // element: <Types />,
        element: <Types />,
      },
      {
        path: "asset-management/mapping",
        // element: <Mapping />,
        element: <Mapping />,
      },
      {
        path: "plants",
        children: [
          {
            path: "*",
            element: <AssetHierarchy />,
          },
        ],
      },
    ],
  },
  {
    path: "eam-prod-overlay",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="eam-prod-overlay/scheduler" replace />,
      },
      {
        path: "scheduler",
        element: <EAMSchedulerDashboard />,
      },
    ],
  },
  {
    path: "locations",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="/locations/list" replace />,
      },
      {
        path: "list",
        element: <LocationList />,
      },
    ],
  },
  {
    path: "usecase",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "motors-and-pumps",
        element: <MotorsAndPumpsDashboard />,
      },
      {
			  path: "motors-and-pumps/disconnectedsensors/:plantId",
			  element: <DisconnectedSensors />,
			},
      {
        path: "utilities",
        element: <UtilitiesDashbord />,
      },
      // {
      //   path: "run-hours",
      //   element: <RunHoursDashboard />,
      // },
      {
        path: "run-hours-pm",
        element: <RunHoursDashboardV2 />,
      },
      {
        path: "wqms",
        element: <WQMSDashboard />,
      },
      {
        path: "servo-drives",
        element: <ServoDrives />,
      },
      {
        path: "aseptic-ccp",
        element: <AsepticCCPDashboard />,
      },
      {
        path: "aseptic-transmitters",
        element: <AsepticTransmittersDashboard />,
      },
      {
        path: "aseptic-valves",
        element: <AsepticValvesDashboard />,
      },
      {
        path: "air-leaks-monitoring",
        element: <AirLeaksDashboard />,
      },
      {
        path: "aseptic-ccp/troubleshoot",
        element: <AsepticCCPTroubleshootDashboard />,
      },
      {
        path: "downtime-request",
        element: <SupplyChain />,
      },
      {
        path: "downtime-request/alerts",
        element: <DowntimeAlerts />,
      },
      {
        path: "downtime-request/list-details/:ticketId",
        element: <TicketDetailsSupplyChain />,
      },
      {
        path: "aseptic-cip",
        element: <AsepticCIP />,
      },
      {
        path: "aseptic-cip/washtable/:plant",
        element: <WashTable />,
      },
      {
        path: "aseptic-cip/exceptions/:plant",
        element: <ExceptionsList />,
      },
      // {
			// 	path: 'injection-analytics',
			// 	element: <InjectionAnalytics />
			// },
			// {
			// 	path: 'injection-analytics/all-tickets',
			// 	element: <InjectionTicketsList />
			// },
			// {
			// 	path: 'injection-analytics/ticket-details/:ticketId',
			// 	element: <InjectionTicketsDetails />
			// },
      // {
			// 	path: 'injection-analytics/config/:reportType',
			// 	element: <InjectionAnalyticsConfig />
			// },
      // {
      //   path: "injection-analytics/cycle-count-monitoring",
      //   element: <InjectionAnalytics />,
      // },
      // {
      //   path: "injection-analytics/cavity-monitoring",
      //   element: <InjectionAnalytics />,
      // },
      // {
      //   path: "injection-analytics/cycle-time-monitoring",
      //   element: <InjectionAnalytics />,
      // },
      {
        path: "inspection-system",
        element: <InspectionSystemDashboard />,
      },
    ],
  },
  {
    path: "aseptic-cip",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "wash-alert",
        children: [
          {
            path: ":alertId",
            element: <WashAlertRedirectionPage />,
          },
        ],
      },
      {
        path: "wash-details",
        children: [
          {
            path: ":washId",
            element: <WashDetails />,
          },
        ],
      },
    ],
  },
  {
    path: "usecase",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "motors-and-pumps/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "utilities/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "wqms/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "servo-drives/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "air-leaks-monitoring/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "aseptic-transmitters/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "aseptic-valves/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "aseptic-ccp/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "inspection-system/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "aseptic-cip",
        element: <AsepticCIP />,
      },
      {
        path: "aseptic-cip/washtable/:plant",
        element: <WashTable />,
      },
      {
        path: "aseptic-cip/exceptions/:plant",
        element: <ExceptionsList />,
      },
      // {
			// 	path: 'injection-analytics',
			// 	element: <InjectionAnalytics />
			// },
			// {
			// 	path: 'injection-analytics/all-tickets',
			// 	element: <InjectionTicketsList />
			// },
			// {
			// 	path: 'injection-analytics/ticket-details/:ticketId',
			// 	element: <InjectionTicketsDetails />
			// },
      // {
			// 	path: 'injection-analytics/config/:reportType',
			// 	element: <InjectionAnalyticsConfig />
			// },
      // {
      //   path: "injection-analytics/cycle-count-monitoring",
      //   element: <InjectionAnalytics />,
      // },
      // {
      //   path: "injection-analytics/cavity-monitoring",
      //   element: <InjectionAnalytics />,
      // },
      // {
      //   path: "injection-analytics/cycle-time-monitoring",
      //   element: <InjectionAnalytics />,
      // },
    ],
  },
  {
    path: "aseptic-cip",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "wash-alert",
        children: [
          {
            path: ":alertId",
            element: <WashAlertRedirectionPage />,
          },
        ],
      },
      {
        path: "wash-details",
        children: [
          {
            path: ":washId",
            element: <WashDetails />,
          },
        ],
      },
    ],
  },
  {
    path: "maintenance",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="/maintenance/tickets" replace />,
      },
      {
        path: "tickets",
        element: <Tickets />,
      },
      {
        path: "ticket-details",
        children: [
          {
            path: ":ticketId",
            element: <TicketDetails />,
          },
          {
            path: ":ticketId/:messageId",
            element: <TicketDetails />,
          },
        ],
      },
      {
        path: "notifications",
        element: <Notification />,
      },
      {
        path: "notification-details",
        children: [
          {
            path: "*",
            element: <NotifyDetails />,
          },
        ],
      },
    ],
  },
  {
    path: "management",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "profile",
        children: [
          {
            path: "",
            element: <Profile />,
          },
        ],
      },
      {
        path: "preferences",
        children: [
          {
            path: "",
            element: <Profile />,
          },
        ],
      },
      {
        path: "send-email",
        children: [
          {
            path: "",
            element: <Profile />,
          },
        ],
      },
      {
        path: "request-access",
        children: [
          {
            path: "",
            element: <Profile />,
          },
        ],
      },
      {
        path: "grant-access",
        children: [
          {
            path: "",
            element: <GrantUserAccessPage />,
          },
        ],
      },
    ],
  },
];

// Supply chain admin routes
const supplyChainAdminRoutes: PartialRouteObject[] = [
  {
    path: "/",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="/usecase/motors-and-pumps" replace />,
      },
      {
        path: "asset-management/usecase",
        element: <Unauthorized />,
      },
      {
        path: "asset-management/pool",
        element: <Unauthorized />,
      },
      {
        path: "asset-management/types",
        // element: <Types />,
        element: <Unauthorized />,
      },
      {
        path: "asset-management/mapping",
        // element: <Mapping />,
        element: <Unauthorized />,
      },

      {
        path: "plants",
        children: [
          {
            path: "*",
            element: <AssetHierarchy />,
          },
        ],
      },
      {
        path: "*",
        element: <Unauthorized />,
      },
    ],
  },
  {
    path: "sat",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="/sat/sat-current/:plantId" />,
      },
      {
        path: "/sat/sat-current/:plantId",
        element: <Unauthorized />,
      },
      {
        path: "sat-sign-off/:plantId",
        element: <Unauthorized />,
      },
      {
        path: "sat-sign-off-history",
        element: <Unauthorized />,
      },
      {
        path: "sat-summary",
        element: <Unauthorized />,
      },
    ],
  },
  {
    path: "locations",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="/locations/list" replace />,
      },
      {
        path: "list",
        element: <LocationList />,
      },
    ],
  },
  {
    path: "usecase",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "motors-and-pumps",
        element: <MotorsAndPumpsDashboard />,
      },
      {
			  path: "motors-and-pumps/disconnectedsensors/:plantId",
			  element: <DisconnectedSensors />,
			},
      {
        path: "utilities",
        element: <UtilitiesDashbord />,
      },
      // {
      //   path: "run-hours",
      //   element: <RunHoursDashboard />,
      // },
      {
        path: "run-hours-pm",
        element: <RunHoursDashboardV2 />,
      },
      {
        path: "wqms",
        element: <WQMSDashboard />,
      },
      {
        path: "servo-drives",
        element: <ServoDrives />,
      },
      { 
        path: "aseptic-ccp", 
        element: <AsepticCCPDashboard /> 
      },
      {
        path: "aseptic-transmitters",
        element: <AsepticTransmittersDashboard />,
      },
      {
        path: "aseptic-valves",
        element: <AsepticValvesDashboard />,
      },
      {
        path: "air-leaks-monitoring",
        element: <AirLeaksDashboard />,
      },
      {
        path: "aseptic-ccp/troubleshoot",
        element: <AsepticCCPTroubleshootDashboard />,
      },
      {
        path: "downtime-request",
        element: <SupplyChain />,
      },
      {
        path: "downtime-request/alerts",
        element: <DowntimeAlerts />,
      },
      {
        path: "downtime-request/list-details/:ticketId",
        element: <TicketDetailsSupplyChain />,
      },
      {
        path: "aseptic-cip",
        element: <AsepticCIP />,
      },
      {
        path: "aseptic-cip/washtable/:plant",
        element: <WashTable />,
      },
      {
        path: "aseptic-cip/exceptions/:plant",
        element: <ExceptionsList />,
      },
      // {
      //   path: "injection-analytics",
      //   element: <InjectionAnalytics />,
      // },
      // {
			// 	path: 'injection-analytics/config/:reportType',
			// 	element: <InjectionAnalyticsConfig />
			// },
      // {
      //   path: "injection-analytics/cycle-count-monitoring",
      //   element: <InjectionAnalytics />,
      // },
      // {
      //   path: "injection-analytics/cavity-monitoring",
      //   element: <InjectionAnalytics />,
      // },
      // {
      //   path: "injection-analytics/cycle-time-monitoring",
      //   element: <InjectionAnalytics />,
      // },
      {
        path: "inspection-system",
        element: <InspectionSystemDashboard />,
      },
    ],
  },
  {
    path: "aseptic-cip",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "wash-alert",
        children: [
          {
            path: ":alertId",
            element: <WashAlertRedirectionPage />,
          },
        ],
      },
      {
        path: "wash-details",
        children: [
          {
            path: ":washId",
            element: <Unauthorized />,
          },
        ],
      },
    ],
  },
  {
    path: "usecase",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "motors-and-pumps/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "utilities/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "wqms/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "servo-drives/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "air-leaks-monitoring/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "aseptic-transmitters/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "aseptic-valves/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "aseptic-ccp/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "inspection-system/:assetId",
        element: <AssetReportPage />,
      },
      {
        path: "aseptic-cip",
        element: <AsepticCIP />,
      },
      {
        path: "aseptic-cip/washtable/:plant",
        element: <WashTable />,
      },
      {
        path: "aseptic-cip/exceptions/:plant",
        element: <ExceptionsList />,
      },
      // {
			// 	path: 'injection-analytics',
			// 	element: <InjectionAnalytics />
			// },
			// {
			// 	path: 'injection-analytics/all-tickets',
			// 	element: <InjectionTicketsList />
			// },
			// {
			// 	path: 'injection-analytics/ticket-details/:ticketId',
			// 	element: <InjectionTicketsDetails />
			// },
      // {
			// 	path: 'injection-analytics/config/:reportType',
			// 	element: <InjectionAnalyticsConfig />
			// },
      // {
      //   path: "injection-analytics/cycle-count-monitoring",
      //   element: <InjectionAnalytics />,
      // },
      // {
      //   path: "injection-analytics/cavity-monitoring",
      //   element: <InjectionAnalytics />,
      // },
      // {
      //   path: "injection-analytics/cycle-time-monitoring",
      //   element: <InjectionAnalytics />,
      // },
    ],
  },
  {
    path: "aseptic-cip",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "wash-alert",
        children: [
          {
            path: ":alertId",
            element: <WashAlertRedirectionPage />,
          },
        ],
      },
      {
        path: "wash-details",
        children: [
          {
            path: ":washId",
            element: <Unauthorized />,
          },
        ],
      },
    ],
  },
  {
    path: "maintenance",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="/tickets/lists" replace />,
      },
      {
        path: "tickets",
        element: <Unauthorized />,
      },
      {
        path: "ticket-details",
        children: [
          {
            path: ":ticketId",
            element: <Unauthorized />,
          },
          {
            path: ":ticketId/:messageId",
            element: <Unauthorized />,
          },
        ],
      },
      {
        path: "notifications",
        element: <Notification />,
      },
      {
        path: "notification-details",
        children: [
          {
            path: "*",
            element: <NotifyDetails />,
          },
        ],
      },
    ],
  },
  {
    path: "management",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "profile",
        children: [
          {
            path: "",
            element: <Profile />,
          },
        ],
      },
      {
        path: "preferences",
        children: [
          {
            path: "",
            element: <Profile />,
          },
        ],
      },
      {
        path: "user-management",
        children: [
          {
            path: "",
            element: <Profile />,
          },
        ],
      },
      {
        path: "request-access",
        children: [
          {
            path: "",
            element: <Profile />,
          },
        ],
      },
      {
        path: "grant-access",
        children: [
          {
            path: "",
            element: <GrantUserAccessPage />,
          },
        ],
      },
    ],
  },
];

const unauthorizedRoutes: PartialRouteObject[] = [
  {
    path: "/",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "",
        element: <Unauthorized />,
      },
      {
        path: "*",
        element: <Unauthorized />,
      },
    ],
  },
  {
    path: "management",
    element: <SidebarWithHeaderLayout />,
    children: [
      {
        path: "profile",
        children: [
          {
            path: "",
            element: <Profile />,
          },
        ],
      },
      {
        path: "request-access",
        children: [
          {
            path: "",
            element: <Profile />,
          },
        ],
      },
      {
        path: "grant-access",
        children: [
          {
            path: "",
            element: <GrantUserAccessPage />,
          },
        ],
      },
    ],
  },
];

export {
  adminRoutes,
  nonAdminRoutes,
  unauthorizedRoutes,
  developerRoutes,
  supplyChainAdminRoutes,
};
